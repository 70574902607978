.container {
  text-align: center;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  padding: 10px 0;
}

.loadingContainer{
  text-align: center;
  display: flex;
  width: 100%;
  color: #797979;
  align-items: center;
  padding: 20px 0;
}