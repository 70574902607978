.loginWrapper {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #4f3ce4 0%, #c51ea9 50%, #f33d5e 100%);
  font-family: Inter;

  .loginBox {
    padding: 40px;
    background-color: white;
    border-radius: 10px;

    .header {
      font-weight: bold;
      font-size: 30px;
      margin-bottom: 5px;
    }

    .loginBtnContainer {
      margin: 35px auto 0 auto;
      text-align: center;
    }

    .loginBtn {
      cursor: pointer;
      background: #0f73ff;
      padding: 10px 33px;
      width: auto;
      border-radius: 30px;
      border: 0;
      color: white;
      font-weight: bold;
      font-size: 18px;
      transition: all 0.3s ease;
    }

    .loginBtn:hover {
      background: #4f3ce4;
    }
  }
}

.loginLoadingContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
