.container {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
}

.profilePaper {
  border: 1px solid #0e4ba0;
  border-radius: 15px !important;
  margin: 25px;
  width: 750px;
}

.topBar {
  padding: 10px 15px;
  background: #0e4ba0;
  color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.muiBtn {
  flex: 1;
  width: 100%;
}