.profilePaper {
  border: 1px solid #0e4ba0;
  border-radius: 15px !important;
  margin: 25px;
  width: 750px;
}

.container {
  padding: 10px 15px;
}

.urlBar {
  padding: 10px 15px;
  background: #0e4ba0;
  color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
}

.metricsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  flex: 1;
  margin-left: -20px;
}

.metricNumber {
  font-size: 30px;
  font-weight: 600;
}

.metricUnit {
  font-size: 20px;
}

.metricTitle {
  margin-top: 5px;
}

.metric {
  margin-top: 15px;
  width: 50%;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttonContainer {
  display: flex;
}

.bigBtnContainer {
  display: flex;
  flex: 1;
}

.muiBtn {
  flex: 1;
  width: 100%;
}